import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,
    useRef,
    useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Avatar from "@material-ui/core/Avatar";
import ProfileIcon from "../../../../Assets/Images/ProfileIcon.png";
import styles from "./MEPannel.module.css";
import Backdrop from '@mui/material/Backdrop';
import {
    getPCCUserProfileSelector,
    getShowMEDrawer,
    getUploadManualEntryStatus
} from "../../../../Reducer/selectors/NursingDashboardSelector";
import { getSelectedPCCPatientSelector } from "../../../../Reducer/selectors/DashboardSelector";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import {
    setShowMEDrawer,
    setUploadManualEntryStatus,
    uploadManualEntry
} from "../../../../Actions";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector.js";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ReactComponent as TempIcon } from "../../../../Assets/icons/01-Temp.svg";
import { ReactComponent as BPIcon } from "../../../../Assets/icons/02-BP.svg";
import { ReactComponent as RRIcon } from "../../../../Assets/icons/03-RR.svg";
import { ReactComponent as PRIcon } from "../../../../Assets/icons/04-PR.svg";
import { ReactComponent as HRIcon } from "../../../../Assets/icons/05-HR.svg";
import { ReactComponent as SPOIcon } from "../../../../Assets/icons/06-SpO2.svg";
import { ReactComponent as HRVIcon } from "../../../../Assets/icons/07-HRV.svg";
import { ReactComponent as PEFIcon } from "../../../../Assets/icons/08-PEF.svg";
import { ReactComponent as FEVIcon } from "../../../../Assets/icons/09-FEV1.svg";
import { ReactComponent as GlucoseIcon } from "../../../../Assets/icons/Glcouse-Icon.svg";
import { ReactComponent as WeightIcon } from "../../../../Assets/icons/Weight-Icon.svg";
import { ReactComponent as WarningIcon } from "../../../../Assets/Images/Alert-Icon-AA346F.svg";
import { CircularProgress } from "@material-ui/core";


dayjs.extend(utc);
dayjs.extend(timezone);
const drawerWidth = 600;
const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: "#f4e7ee",
        width: drawerWidth,
    },
    typoStyle: {
        fontSize: 20,
        textTransform: `capitalize;`,
        fontFamily: `GTWalsheimProRegular`,
        width: 100,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        justifyContent: "flex-start",
        backgroundColor: "#E4DFE9",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const MEPannel = forwardRef((props, ref) => {

    const refOne = useRef(null);
    const classesQV = useStyles();
    const dispatch = useDispatch();
    const isMEOpen = useSelector(getShowMEDrawer);
    const [stepNumber, setStepNumber] = useState(1);
    const selectedPCCPatient = useSelector(getSelectedPCCPatientSelector);
    const selectedPCCPatientProfile = useSelector(getPCCUserProfileSelector);
    const [newNotes, setNewNotes] = useState("");
    const [measurementSuccess, setMeasurementSuccess] = useState(false);
    const manualEntryStatus = useSelector(getUploadManualEntryStatus);
    const [showLoader, setShowLoader] = useState(false);
    const [displayStatus, setDisplayStatus] = useState(false);
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [errors, setErrors] = useState(new Map());

    const checkAnyErrors = () => {
        let isErrors = false;
        for (let value of errors.values()) {
            if (value !== "") {
                isErrors = true;
            }
        }

        return isErrors;
    }

    useEffect(() => {
        if (checkAnyErrors()) {
            setIsSubmitDisabled(true);
        } else {
            setIsSubmitDisabled(false);
        }
    }, [errors]);

    const handleQuickDrawerOpen = () => {
        dispatch(setShowMEDrawer(true));
        setTimestamp(new Date());
    };

    const resetMeasurements = () => {
        setPainLevel();
        setWeight("");
        setGlucoseLevel("");
        setTemp("");
        setSpo2("");
        setBreathingRate("");
        setPulseRate("");
        setHeartRate("");
        setHrv("");
        setSbp("");
        setdbp("");
        setFev1("");
        setPef("");
        setNewNotes("");
        setErrors(new Map());
    }

    const retakeMeasurements = () => {
        setStepNumber(1);
        resetMeasurements();
        setDisplayStatus(false);
        setTimestamp(new Date());
    }

    const handleQuickDrawerClose = () => {
        dispatch(setShowMEDrawer(false));
        setStepNumber(1);
        resetMeasurements();
        setDisplayStatus(false);
    };

    const handleSubmitFinalMeasurements = () => {
        if (isSubmitDisabled) {
            return;
        }
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        setShowLoader(true);
        const payload = {
            subjectId: selectedPCCPatientProfile.subjectId,
            temperature: temp === "" ? undefined : "\"" + temp + ", 1.00\"",
            spo2: spo2 === "" ? undefined : "\"" + spo2 + ", 1.00\"",
            breathingRate: breathingRate === "" ? undefined : "\"" + breathingRate + ", 1.00\"",
            pulseRate: pulseRate === "" ? undefined : "\"" + pulseRate + ", 1.00\"",
            heartRate: heartRate === "" ? undefined : "\"" + heartRate + ", 1.00\"",
            hrv: hrv === "" ? undefined : "\"" + hrv + ", 1.00\"",
            sbp: sbp === "" ? undefined : "\"" + sbp + ", 1.00\"",
            dbp: dbp === "" ? undefined : "\"" + dbp + ", 1.00\"",
            fev1: fev1 === "" ? undefined : "\"" + fev1 + ", 1.00\"",
            pef: pef === "" ? undefined : "\"" + pef + ", 1.00\"",
            weight: weight === "" ? undefined : weight,
            glucose: glucoseLevel === "" ? undefined : glucoseLevel,
            painLevel: painLevel === "" ? undefined : painLevel,
            tsRecorded: useManualTime ? dayjs.tz(
                `${manualDateTime.format('YYYY-MM-DDTHH:mm:ss')}`,
                manualTimezone
            ).utc().toISOString() : timestamp,
            timezone: useManualTime ? manualTimezone : timeZone,
            note: newNotes,
            createdBy: userProfile?.id,
        }
        console.log("MANUAL ENTRY PAYLOAD: ", payload);
        dispatch(uploadManualEntry(payload));
        closeLoaderIn5Seconds();
    }

    const [temp, setTemp] = useState();
    const [spo2, setSpo2] = useState();
    const [breathingRate, setBreathingRate] = useState();
    const [pulseRate, setPulseRate] = useState();
    const [heartRate, setHeartRate] = useState();
    const [hrv, setHrv] = useState();
    const [sbp, setSbp] = useState();
    const [dbp, setdbp] = useState();
    const [fev1, setFev1] = useState();
    const [pef, setPef] = useState();
    const [painLevel, setPainLevel] = useState();
    const [weight, setWeight] = useState();
    const [glucoseLevel, setGlucoseLevel] = useState();

    const [timestamp, setTimestamp] = useState(new Date());
    const [useManualTime, setUseManualTime] = useState(false);
    const [manualDateTime, setManualDateTime] = useState(dayjs());
    const [manualTimezone, setManualTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    //console.log("errors: ", errors);

    const healthMetrics = [
        {
            key: "temp",
            value: temp,
            setter: setTemp,
            label: 'Temp',
            icon: TempIcon,
            unit: 'F',
            error: errors.get('temp'),
            min: 94,
            max: 109
        },
        {
            key: "sbp",
            value: sbp,
            setter: setSbp,
            label: 'SBP',
            icon: BPIcon,
            unit: 'mmHg',
            error: errors.get('sbp'),
            min: 50,
            max: 250,
            dependentValue: dbp,
            dependentKey: "dbp",
            dependentLabel: 'DBP'
        },
        {
            key: "dbp",
            value: dbp,
            setter: setdbp,
            label: 'DBP',
            icon: BPIcon,
            unit: 'mmHg',
            error: errors.get('dbp'),
            min: 30,
            max: 150,
            dependentValue: sbp,
            dependentKey: "sbp",
            dependentLabel: 'SBP'
        },
        {
            key: "heartRate",
            value: heartRate,
            setter: setHeartRate,
            label: 'HR',
            icon: HRIcon,
            unit: 'bpm',
            error: errors.get('heartRate'),
            min: 30,
            max: 250
        },
        {
            key: "pulseRate",
            value: pulseRate,
            setter: setPulseRate,
            label: 'PR',
            icon: PRIcon,
            unit: 'bpm',
            error: errors.get('pulseRate'),
            min: 30,
            max: 250
        },
        {
            key: "hrv",
            value: hrv,
            setter: setHrv,
            label: 'HRV',
            icon: HRVIcon,
            unit: 'ms',
            error: errors.get('hrv'),
            min: 1,
            max: 200
        },
        {
            key: "spo2",
            value: spo2,
            setter: setSpo2,
            label: 'SpO2',
            icon: SPOIcon,
            unit: '%',
            error: errors.get('spo2'),
            min: 60,
            max: 100
        },
        {
            key: "pef",
            value: pef,
            setter: setPef,
            label: 'PEF',
            icon: PEFIcon,
            unit: 'L/sec',
            error: errors.get('pef'),
            min: 0.5,
            max: 15
        },
        {
            key: "fev1",
            value: fev1,
            setter: setFev1,
            label: 'FEV1',
            icon: FEVIcon,
            unit: 'L',
            error: errors.get('fev1'),
            min: 0.2,
            max: 12
        },
        {
            key: "breathingRate",
            value: breathingRate,
            setter: setBreathingRate,
            label: 'RR',
            icon: RRIcon,
            unit: 'br/min',
            error: errors.get('breathingRate'),
            min: 6,
            max: 50
        },
        {
            key: "weight",
            value: weight,
            setter: setWeight,
            label: 'Weight',
            icon: WeightIcon,
            unit: 'lbs',
            error: errors.get('weight'),
            min: 20,
            max: 1000
        },
        {
            key: "glucoseLevel",
            value: glucoseLevel,
            setter: setGlucoseLevel,
            label: 'Gluc.',
            icon: GlucoseIcon,
            unit: 'mg/dL',
            error: errors.get('glucoseLevel'),
            min: 20,
            max: 500
        }
    ];

    const updateErrors = (key, value) => {
        setErrors(map => new Map(map.set(key, value)));
    };

    const validateField = (key, value, min, max, label, dependentKey, dependentValue, dependentLabel) => {
        if (value === "--" || value === "") {
            if (dependentKey && dependentValue !== "") {
                let msg = `${label} is required if ${dependentLabel} is present`;
                updateErrors(key, msg);
            } else {
                updateErrors(key, "");
                updateErrors(dependentKey, "");
            }
            return;
        }

        let errorMsg = "";
        const numValue = parseFloat(value);
        if (isNaN(numValue) || numValue < min || numValue > max) {
            errorMsg = `${label} must be between ${min} and ${max}`;
        }

        if (dependentKey) {
            if (dependentValue === "" || dependentValue === "--") {
                let msg = `${dependentLabel} is required if ${label} is present`;
                updateErrors(dependentKey, msg);
            }
        }

        if (errorMsg !== "") {
            updateErrors(key, errorMsg);
        } else {
            updateErrors(key, "");
        }
    };

    const handleFocus = (event) => event.target.select();

    useImperativeHandle(ref, () => {
        return {
            handleQuickDrawerOpen: handleQuickDrawerOpen,
        };
    });
    const closeLoaderIn5Seconds = () => {
        setTimeout(() => {
            setShowLoader(false);
            setDisplayStatus(true);
        }, 3000);
    };

    useEffect(() => {
        if (manualEntryStatus && displayStatus) {
            closeDrawerIn3Seconds();
        }
    }, [manualEntryStatus, displayStatus]);

    const closeDrawerIn3Seconds = () => {
        setTimeout(() => {
            dispatch(setUploadManualEntryStatus(false));
            dispatch(setShowMEDrawer(false));
            setStepNumber(1);
            resetMeasurements();
            setDisplayStatus(false);
        }, 2000);
    };

    const handleInputChange = (e, setter, key, value, min, max, label, dependentKey, dependentValue, dependentLabel) => {
        //e.preventDefault();
        setter(e.target.value);
        validateField(key, e.target.value, min, max, label, dependentKey, dependentValue, dependentLabel);
    };

    const getFirstErrorMsg = (errorsMap) => {
        for (let value of errorsMap.values()) {
            if (value !== "") {
                return value;
            }
        }

        return "";
    };

    return (
        <div className={classesQV.root} ref={refOne}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={isMEOpen}
            ></Backdrop>
            <main></main>
            <Drawer
                className={classesQV.drawer}
                anchor="right"
                variant="persistent"
                open={isMEOpen}
                classes={{
                    paper: classesQV.drawerPaper,
                }}
            >

                <div className={styles.qvHeader}>
                    <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar>
                    <div className={styles.avatarTitleDiv}>
                        <div className={styles.avatarTitle}> {selectedPCCPatient.fullName} </div>
                        <div className={styles.avatarTitle1}> {selectedPCCPatient.medicalRecordNumber} </div>
                    </div>
                </div>

                <div className={styles.drawerTitle}>
                    <TouchAppIcon></TouchAppIcon> Manual Measurement
                </div>

                {(getFirstErrorMsg(errors) !== "") && (
                    <div className={styles.errorDiv}>
                        <div className={styles.errorMsgDiv}>
                            <div className={styles.warningIcon}>
                                <WarningIcon />
                            </div>
                            <div className={styles.errorText}>
                                {getFirstErrorMsg(errors)}
                            </div>
                        </div>
                    </div>
                )}

                {stepNumber === 1 && (<>
                    <div className={styles.qvDiv}>
                        <Grid
                            container
                            spacing={0}
                            style={{ padding: 10 }}
                        >
                            {healthMetrics.map(({ key, value, setter, label, icon: IconComponent, unit, min, max, dependentKey, dependentValue, error, dependentLabel }, index) => {
                                console.log("healtMetrics err:", error);
                                return (
                                    <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                        <div className={styles.cardHighRisk}>
                                            <div className={styles.iconDivStyles}>
                                                <IconComponent className={styles.highRiskIcon} size={40} />
                                            </div>
                                            <div className={styles.countDivStyles}>
                                                <input
                                                    key={key}
                                                    type="number"
                                                    placeholder="--"
                                                    value={value}
                                                    onChange={(e) => handleInputChange(e, setter, key, value, min, max, label, dependentKey, dependentValue, dependentLabel)}
                                                    className={(error && (error !== "")) ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                                    onFocus={handleFocus}
                                                    onKeyDown={e => {
                                                        if (e.key === '.' || e.key === 'e') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                //onKeyUp={validateField(key, value, min, max, label, dependentKey, dependentValue )}
                                                />
                                                <div className={styles.patientCatagoryHighRisk}>
                                                    {label} ({unit})
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                );
                            })}

                            <div className={styles.painDiv}>
                                <div className={styles.painDivDesc}>
                                    Rate your pain level on a scale of 0 - 10
                                </div>
                                <div className={styles.painLevelRating}>
                                    {Array.from({ length: 11 }, (_, index) => (
                                        <div
                                            key={index}
                                            className={painLevel >= index ? styles.selectedRatingdiv : styles.ratingdiv}
                                            onClick={() => setPainLevel(index)}
                                        >
                                            {index}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className={styles.addNewNoteDiv}>
                                <Input
                                    className={styles.notesEditor}
                                    type="text"
                                    placeholder="Start typing..."
                                    value={newNotes}
                                    multiline={true}
                                    onChange={(event) => setNewNotes(event.target.value)}
                                    disableUnderline="true"
                                    minRows={2}
                                    maxRows={3}
                                />
                            </div>
                            <div className={styles.timeStampStyles}>{timestamp.toString()}</div>
                        </Grid>

                        <div className={styles.loaderDiv}>
                            {showLoader && <CircularProgress style={{ color: `#4E253A` }} ></CircularProgress>}
                            {displayStatus && (manualEntryStatus ? (<div className={styles.loaderMessageSuccess}>Measurement submitted successfully!</div>) : (<div className={styles.loaderMessageFailure}>Measurement submission failed.</div>))}
                        </div>
                        <div className={styles.buttonsDiv}>
                            <div className={styles.retakeButton} onClick={() => handleQuickDrawerClose()}>
                                Cancel
                            </div>
                            {(displayStatus && !manualEntryStatus) ? (
                                <div className={styles.completeButton} onClick={() => retakeMeasurements()}>
                                    Retake
                                </div>
                            ) : (
                                <div className={isSubmitDisabled ? styles.completeButtonDisabled : styles.completeButton} onClick={() => handleSubmitFinalMeasurements()}>
                                    Submit
                                </div>
                            )}
                        </div>


                    </div>

                </>)}

                {stepNumber === 3 && !measurementSuccess && (<>
                    <div className={styles.mlInsDiv}>
                    </div>
                    <div className={styles.cancelButton} onClick={() => handleQuickDrawerClose()}>
                        Cancel
                    </div>
                    <div className={styles.stepTwoWarningDiv}>
                        The measurement upload failed. Please retry manually entering the measurements.
                    </div>
                </>)}
            </Drawer>
        </div >
    );
});
export default MEPannel;
